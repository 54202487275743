<template>
<div>
    <modal :show.sync="show">
        <template slot="header">
            <h5 class="modal-title">{{ title }}</h5>
        </template>
        <div>
            <validation-observer ref="formValidator">
                <form class="needs-validation" autocomplete="off">
                    <!-- CAMPO STATUS -->
                    <div>
                        <div class="form-group row m-0 p-0">
                            <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                                Status
                                <span class="text-danger">&nbsp;*</span>
                            </label>
                            <div class="col-md-7 pt-2">
                                <base-switch type="success" offText="inativo" onText="ativo" class="success" v-model="bankAccount.status"></base-switch>
                            </div>
                        </div>
                    </div>

                    <!-- CAMPO NAME -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Nome da conta
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="bankAccount.name"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- Código externo -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0  col-form-label form-control-label">
                            Cód. Externo
                        </label>
                        <div class="col-md-7">
                            <base-input input-classes="form-control-sm">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="bankAccount.external_code"
                                    maxlength="50"
                                    :disabled="loadingExternalCode"
                                    @blur="setSapExternalCode"
                                />
                            </base-input>
                        </div>
                    </div>

                    <div class="card-header border-header-primary-bottom mt-3 mb-2 p-2">
                        <h5 class="h4 mb-0" slot="title">Dados do banco</h5>
                    </div>
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Banco
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-group-classes="input-group-sm">
                                    <puzl-select
                                        v-model="bankAccount.bank_id"
                                        :items="banks"
                                        :labelMask="true"
                                        label="$code$ - $name$"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO NUMBER WITHOUT DIGIT AGENCY -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Número sem dígito
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="bankAccount.number_without_digit_agency"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO DIGITY AGENCY -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Dígito
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <base-input input-classes="form-control-sm">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    v-model="bankAccount.digit_agency"
                                />
                            </base-input>
                        </div>
                    </div>

                    <div class="card-header border-header-warning-bottom mt-3 mb-2 p-2">
                        <h5 class="h4 mb-0" slot="title">Dados da conta</h5>
                    </div>

                    <!-- CAMPO NUMBER WITHOUT DIGIT ACCOUNT -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Número sem dígito
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="bankAccount.number_without_digit_account"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO DIGIT ACCOUNT -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Dígito
                            <span class="text-danger">&nbsp;*</span>
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <validation-provider rules="required" v-slot="{errors}">
                                <base-input input-classes="form-control-sm">
                                    <input
                                        type="text"
                                        class="form-control form-control-sm"
                                        v-model="bankAccount.digit_account"
                                        :class="errors[0] ? 'is-invalid' : 'is-valid'"
                                    />
                                </base-input>
                            </validation-provider>
                        </div>
                    </div>

                    <!-- CAMPO ACCOUNT TYPE -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Tipo de Conta
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <base-input input-classes="form-control-sm">
                                <input type="text" class="form-control form-control-sm" v-model="bankAccount.account_type" />
                            </base-input>
                        </div>
                    </div>

                    <!-- CAMPO BALANCE -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Saldo
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <base-input input-group-classes="input-group-sm">
                                <input
                                    v-money="money"
                                    maxlength="13"
                                    inputmode="numeric"
                                    v-model.lazy="bankAccount.balance"
                                    type="text"
                                    class="form-control form-control-sm"
                                />
                                <template slot="prepend">
                                    <small class="input-group-sm p-0 m-0">
                                        R$
                                    </small>
                                </template>
                            </base-input>
                        </div>
                    </div>

                    <!-- CAMPO OBSERVATION -->
                    <div class="form-group row m-0 p-0">
                        <label class="col-md-5 pb-0 mb-1 col-form-label form-control-label">
                            Observações
                        </label>
                        <div class="col-md-7 mb-1 pr-4">
                            <base-input input-group-classes="input-group-sm">
                                <textarea type="text" rows="2" class="form-control form-control-sm" v-model="bankAccount.observation">
                  </textarea>
                            </base-input>
                        </div>
                    </div>

                    <!-- MODAL FOOTER -->
                    <div class="modal-footer">
                        <base-button type="secondary" @click="close()">
                            Cancelar
                        </base-button>
                        <base-button @click.prevent="handleSubmit()" type="success" :loading="isLoading">
                            Salvar
                        </base-button>
                    </div>
                </form>
            </validation-observer>
        </div>
    </modal>
</div>
</template>

<script>
import {mapGetters} from 'vuex';
import {VMoney} from "v-money";
import PuzlSelect from "@/components/PuzlSelect";
const {formatErrorValidation} = require("@/plugins")
// Sap
import sapIntegrationStore from "@/modules/sap/sap-integration/store";
import { hasSap } from "@/modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "@/modules/sap/sap-integration/types";
import { SapTypeEnum } from "@/modules/sap/shared/enums/SapTypeEnum";

export default {
    name: "ModalCreate",
    components: { PuzlSelect },
    data() {
        return {
            title: "Editar Conta Bancária",
            show: false,
            isLoading: false,
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false
            },
            hasSap: hasSap(),
            SapTypeEnum: SapTypeEnum,
            loadingExternalCode: false,
        };
    },
    mounted() {
        this.$refs.formValidator.validate()
        this.$store.dispatch('bank/fetchItems')
    },
    directives: {
        money: VMoney
    },
    computed: {
        ...mapGetters({
            'banks': 'bank/fetch',
            'bankAccount': 'bankAccount/show'
        }),
    },
    methods: {
        close() {
            this.show = false;
        },
        handleEdit(id) {
            let loader = this.$loading.show()
            this.$notify({type: 'info', message: 'Estamos trabalhando em sua solicitação.'});
            this.$store.dispatch('bankAccount/show', id)
                .then((response) => {
                    this.show = true
                    loader.hide()
                    this.$notify({
                        type: "success",
                        message: "Solicitação realizada com sucesso!",
                    });
                    this.getSapExternalCode();
                }).catch((error) => {
                    this.$notify({
                        type: error.data.error_type,
                        message: error.data.message
                    })
                    loader.hide();
                })
        },
        handleSubmit() {
            this.$Progress.start()
            this.loadingSave = true
            const payload = this.formatBeforeSave({...this.bankAccount});
            this.$store.dispatch('bankAccount/update', payload)
                .then(response => {
                    this.loadingSave = false
                    this.show = false
                    this.$Progress.finish()
                    this.$notify({
                        type: response.error_type,
                        message: response.message
                    })
                })
                .catch(error => {
                    if (error.response && error.response.status === 422) {
                        let errors = Object.values(error.response.data.errors)
                        errors = errors.flat().toString().split(',').join("<br />")
                        this.$notify({type: 'danger', message: errors})
                    }
                    else {
                        this.$notify({type: 'danger', message: error.data.message})
                    }

                    this.$Progress.finish()
                    this.loadingSave = false
                })
        },
        formatBeforeSave(payload){
            payload.balance = payload.balance.replaceAll('.', '').replace(',', '.')
          return payload;
        },
        async getSapExternalCode() {
            if(this.hasSap) {
                this.loadingExternalCode = true;
                const response = await sapIntegrationStore.getExternalId(this.bankAccount.id, SapTypeEnum.keys.BANK)
                this.bankAccount.external_code = response;
                this.loadingExternalCode = false;
            }
        },
        async setSapExternalCode() {
            if(this.hasSap) {
                const loader = this.$loading.show();
                let data = initSapIntegrationSyncType();
                data.typeable_id = this.bankAccount.id;
                data.type = SapTypeEnum.keys.BANK;
                data.external_id = this.bankAccount.external_code;
                await sapIntegrationStore.sync(data);
                loader.hide();
            }
        },
    },
};
</script>

<style scoped></style>
