<template>
  <div>
    <modal size="md" :show.sync="modal.create" :backgroundColor="'#F2F7F3'" :showClose="false" :useRerender="false" >
      <template>
        <template slot="header">
          <div class="d-flex align-items-center container ml-1 w-100" style="gap: 15px;">
            <div>
              <img src="/img/brand/logo.png" alt="logo" width="20px">
            </div>
            <span class="text-title">
              {{ modal.title }}
            </span>
            <div class="ml-auto">
              <img class="pointer" @click="closeModal" src="/img/icons/icons8/ios/close-window.png" alt="close-window"
                width="17px">
            </div>
          </div>
        </template>
      </template>
      <div>
        <validation-observer v-slot="{ invalid, handleSubmit }" ref="formValidator">
          <form class="needs-validation" @submit.prevent="handleSubmit(submitAndClose)" autocomplete="off">
            <div class="container-fluid">
              <div class="row">
                <div class="col-12" style="display: flex; align-items: center;">
                  <img src="/img/icons/icons8/ios/info-squared.png" width="20">
                  <h4 class="m-0 mx-3" style="font-size: 16px; font-weight: 500; color: #2B2D32;">
                    Identificação
                  </h4>
                  <hr class="ml-3" style="width: 100%;">
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    Status
                  </div>
                </div>
                <div v-if="formData.is_active === true" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-success"
                    @click="formData.is_active = false;">
                    <img src="/img/icons/ok--v1.png" alt="ok--v1" width="12px">
                    Ativo
                  </span>
                </div>
                <div v-if="formData.is_active === false" class="col-md-7 mb-2 px-0">
                  <span class="d-flex float-right align-items-center status-button-danger" @click="formData.is_active = true;">
                    <img src="/img/icons/icons8/ios/shutdown--v1_danger.png" alt="shutdown--v1_danger" width="12px">
                    Inativo
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                      CNPJ Inicial
                      <span class="text-danger">&nbsp;*</span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required|min:18|document" v-slot="{errors}">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input 
                          v-model="formData.initial_ein" 
                          type="text" 
                          class="form-control form-control-sm" 
                          :disabled="true"
                          :class="errors[0] ? 'is-invalid' : 'is-valid'"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                      Nome da empresa
                      <span class="text-danger">&nbsp;*</span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                        <input v-model="formData.name" type="text" class="form-control form-control-sm" />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                      Regime Tributário
                      <span class="text-danger">&nbsp;*</span>
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <validation-provider rules="required" v-slot="{errors}">
                      <base-input input-classes="form-control-sm">
                        <PuzlSelect
                          v-model="formData.tax_regime"
                          label="label"
                          customKey="value"
                          :items="taxRegimeTitles"
                          :disableBoxShadow="true"
                        />
                      </base-input>
                    </validation-provider>
                  </div>
                </div>
                <div class="form-group row m-0 p-0">
                  <div class="col-md-5 my-1 px-0">
                    <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                      Logo
                    </div>
                  </div>
                  <div class="col-md-7 mb-2 px-0">
                    <InputFile ref="files" :maxFiles="1" helpText="Escolha a imagem ou arraste aqui." />
                  </div>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    Código Totvs
                  </div>
                </div>
                <div class="col-md-7 mb-2 px-0">
                  <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                    <input v-model="formData.totvs_code" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    Código SAP
                  </div>
                </div>
                <div class="col-md-7 mb-2 px-0">
                  <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                    <input @blur="setSapExternalCode" :disabled="loadingExternalCode" v-model="formData.sap_code" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="form-group row m-0 p-0">
                <div class="col-md-5 my-1 px-0">
                  <div class="pb-0 mb-1 form-control-label" style="font-size: 14px; font-weight: 400;">
                    Código Externo
                  </div>
                </div>
                <div class="col-md-7 mb-2 px-0">
                  <base-input input-classes="form-control-sm" :disableBoxShadow="true">
                    <input v-model="formData.external_code" type="text" class="form-control form-control-sm" />
                  </base-input>
                </div>
              </div>
              <div class="modal-footer px-0">
                <div class="d-flex pr-2" style="gap: 18px;">
                  <div class="d-flex align-items-center button-cancel" style="gap: 5px;" @click="closeModal">
                    <img src="/img/icons/icons8/ios/cancel_danger.png" alt="cancel_danger" width="16px">
                    <span style="font-weight: 500;">
                      Cancelar
                    </span>
                  </div>
                  <div 
                    type="success"
                    @click="submitAndClose"
                    class="d-flex align-items-center button-save" 
                    style="gap: 5px;" 
                  >
                    <img src="/img/icons/icons8/ios/save_success.png" alt="save_success" width="16px">
                    <span style="font-weight: 500;">
                      Salvar
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </validation-observer>
      </div>
    </modal>
  </div>
</template>

<script>
import PuzlSelect from "@/components/PuzlSelect";
import { TaxRegimeEnum, getTaxRegimeEnumByTitle, getTaxRegimeTitles } from "../../../../../../enum/TaxRegimeEnum";
import InputFile from "@/components/Utils/InputFile";
import { urlToFile, fileToBase64 } from "../../../../../../helpers";
const {formatErrorValidation} = require("@/plugins")
// Sap
import sapIntegrationStore from "@/modules/sap/sap-integration/store";
import { hasSap } from "@/modules/sap/shared/helpers/sapHelper";
import { initSapIntegrationSyncType } from "@/modules/sap/sap-integration/types";
import { SapTypeEnum } from "@/modules/sap/shared/enums/SapTypeEnum";

export default {
  name: "ModalIssuerGroup",
  components: {
    PuzlSelect,
    InputFile,
  },
  data() {
    return {
      modal: {
        title: "Empresa",
        create: false,
      },
      taxRegimeEnum: TaxRegimeEnum,
      getTaxRegimeEnumByTitle: getTaxRegimeEnumByTitle,
      isLoading: false,
      isUpdateRecord: false,
      taxRegimeTitles: [],
      formData: {
        id: null,
        is_active: true,
        name: '',
        initial_ein: '',
        tax_regime: '',
        logo_name: '',
        logo_base64: '',
        totvs_code: null,
        sap_code: '',
        external_code: '',
      },
      hasSap: hasSap(),
      SapTypeEnum: SapTypeEnum,
      loadingExternalCode: false,
    };
  },
  mounted() {
    this.$refs.formValidator.validate();
    this.taxRegimeTitles = getTaxRegimeTitles();
  },
  methods: {
    closeModal() {
      this.modal.create = false;
    },
    /**
     * Criar Grupo de emissor de cnpj (empresa). 
     * Inicializa `formData` para novo registro e abre o modal de criação.
     * 
     * @param {Object} companyPlantIssuerData - Dados do emissor da planta.
     * @param {string} companyPlantIssuerData.group_name
     * @param {string} companyPlantIssuerData.business_name
     * @param {string} companyPlantIssuerData.ein
     * @param {string} companyPlantIssuerData.tax_regime
     */
    handleCreate(companyPlantIssuerData) {
      this.isUpdateRecord = false;
      this.formData = {
        is_active: true,
        name: companyPlantIssuerData.group_name || companyPlantIssuerData.business_name.split('|')[0].trim(),
        initial_ein: companyPlantIssuerData.ein.substring(0, 10),
        tax_regime: getTaxRegimeEnumByTitle(companyPlantIssuerData.tax_regime),
        logo_name: '',
        logo_base64: '',
        logo_url: '',
        totvs_code: null,
        sap_code: '',
        external_code: '',
      }
      this.modal.create = true;
    },
    /**
     * Editar Grupo de emissor de cnpj (empresa). 
     * Inicializa `formData` para editar registro e abre o modal de criação.
     * 
     * @param {string|number} companyPlantIssuerGroupId - ID
     */
    handleEdit(companyPlantIssuerGroupId) {
      this.isUpdateRecord = true;
      this.isLoading = true;
      let loader = this.$loading.show();
      this.$store
        .dispatch("companyPlantIssuerGroup/show", companyPlantIssuerGroupId)
        .then(async ({data}) => {
          this.formData = {
            id: data.id,
            is_active: data.is_active,
            name: data.name,
            initial_ein: data.initial_ein,
            tax_regime: data.tax_regime,
            logo_url: data.logo_url,
            logo_name: data.logo_name,
            logo_base64: '',
            totvs_code: data.totvs_code,
            sap_code: data.sap_code,
            external_code: data.external_code,
            company_plant_issuer_has_groups: data.company_plant_issuer_has_groups,
          }
          if (data.logo_url) {
            const file = await urlToFile(data.logo_url, data.logo_name);
            this.$refs.files.setFilesByUrl([{
              file_name: file.name,
              file_size: file.size,
              type: file.type,
              ext: file.name.split('.').pop(),
              url: data.logo_url,
            }]);
          }
          this.modal.create = true;
          this.getSapExternalCode();
        })
        .catch((error) => {
          const errors = error && error.response && error.response.status === 422
            ? formatErrorValidation(error.response.data.errors)
            : error.data.message;
          this.$notify({ type: "danger", message: errors });
        })
        .finally(() => {
          this.isLoading = false;
          loader.hide();
        });
    },
    async submitAndClose() {
      // Converter logo para base64
      if (this.$refs.files.fileRecords.length) {
        const file = this.$refs.files.fileRecords[0].file ?? this.$refs.files.fileRecords[0];
        const fileName = typeof file.name === 'function' ? file.name() : file.name;
        const fileExtension = fileName.split('.').pop();
        const initialEin = this.$helper.onlyNumbers(this.formData.initial_ein);
        this.formData.logo_base64 = await fileToBase64(file);
        this.formData.logo_name = `${initialEin}.${fileExtension}`;
      }

      // Atualizar registro
      if (this.isUpdateRecord) {
        this.isLoading = true;
        let loader = this.$loading.show();
        this.$store
          .dispatch('companyPlantIssuerGroup/update', this.formData)
          .then((response) => this.$notify({type: 'success', message: response.message}))
          .catch((error) => {
            const errors = error && error.response && error.response.status === 422
              ? formatErrorValidation(error.response.data.errors)
              : error.data.message;
            this.$notify({ type: "danger", message: errors });
          })
          .finally(() => {
            this.isLoading = false;
            loader.hide();
          });
      }

      this.$emit('submitted', this.formData);
      this.closeModal();
    },

    async getSapExternalCode() {
      if(this.hasSap) {
        this.loadingExternalCode = true;
        const response = await sapIntegrationStore.getExternalId(this.formData.id, SapTypeEnum.keys.COMPANY_PLANT_ISSUER_GROUP)
        this.formData.sap_code = response;
        this.loadingExternalCode = false;
      }
    },
    async setSapExternalCode() {
      if(this.hasSap) {
        const loader = this.$loading.show();
        let data = initSapIntegrationSyncType();
        data.typeable_id = this.formData.id;
        data.type = SapTypeEnum.keys.COMPANY_PLANT_ISSUER_GROUP;
        data.external_id = this.formData.sap_code;
        await sapIntegrationStore.sync(data);
        loader.hide();
      }
    },
  },
};
</script>
<style scoped>
.text-title {
  font-size: 18px;
  font-weight: 500;
  color: #2B2D32;
}

.status-button-success {
  background-color: #F2F7F3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #149E57;
  font-weight: 400;
  padding: 4px 40px;
}

.status-button-danger {
  background-color: #F4C7C3;
  border-radius: 16px;
  cursor: pointer;
  gap: 5px;
  font-size: 12px;
  color: #DB4539;
  font-weight: 400;
  padding: 4px 34px
}

.button-cancel {
  cursor: pointer;
  border: 0.5px solid #DB4539;
  border-radius: 8px;
  color: #DB4539;
  padding: 4px 20px;
}

.button-save {
  cursor: pointer;
  border: 0.5px solid #149E57;
  border-radius: 8px;
  color: #149E57;
  padding: 4px 30px;
}
</style>
